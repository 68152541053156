import * as React from 'react';
import {Collection, ProductList} from '@tipser/tipser-elements';

import { Header } from '../../components/Header';
import { Content } from '../../components/Content';
import { Footer } from '../../components/Footer';

const COLLECTION_ID = '616a39a63d50b55e25f3fbfd';

export const CollectionView = () => {
    return (
        <>
            <Header />
            <Content>
                <h2 className="heading">Collection</h2>
                <Collection collectionId={COLLECTION_ID} />

                <h2 className="heading">Product list</h2>
                <ProductList productIds={["608fc1446c20b7dc6342e037", "60cc402b1537e4946257be17"]} />
            </Content>
            <Footer />
        </>
    );
};
