import * as React from 'react';
import { Carousel } from '@tipser/tipser-elements';

import { Header } from '../../components/Header';
import { Content } from '../../components/Content';
import { Footer } from '../../components/Footer';

export const CarouselView = () => {
    return (
        <>
            <Header />
            <Content>
                <h2 className="heading">Collection carousel</h2>
                <Carousel collectionId="616a39457d6b985dfc9887d5" />

                <h2 className="heading">Product list carousel</h2>
                <Carousel productIds={["6168a4e3e705fb536bf20eff", "6168a4e3e705fb536bf20ed5", "6168a4e3e705fb536bf20f16", "6168a4e3e705fb536bf20f3a", "6168a4e3e705fb536bf21036", "6168a4e3e705fb536bf210cf", "6168a4e3e705fb536bf20ff0", "6168a4e3e705fb536bf210e1"]} />
            </Content>
            <Footer />
        </>
    );
};
