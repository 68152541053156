import React from 'react';
import {Link} from 'react-router-dom';

import logo from '../../logo.svg';

import './Footer.css';

export const Footer = () => {
    return (
        <footer>
            {/*<div className="footer-terms">*/}
            <div className="footer-logo">
                <Link className="tipser-logo" to="/">
                    <img src={logo} alt="tipser logo"/>
                </Link>
            </div>
        </footer>
    );
}
